import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { marked } from 'marked';
import io from 'socket.io-client';
import AnsiToHtml from 'ansi-to-html';

import './styles.css';

const ChatApp = () => {
  const [messages, setMessages] = useState(() => {
    const savedMessages = sessionStorage.getItem('messages');
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const [commands, setCommands] = useState(() => {
    const savedCommands = sessionStorage.getItem('commands');
    return savedCommands ? JSON.parse(savedCommands) : [];
  });
  const [input, setInput] = useState('');
  const [currentGoal, setCurrentGoal] = useState('No current goal.');
  const [planItems, setPlanItems] = useState([
    'Query Argus to generate a plan.',
  ]);
  const socket = useRef(null);
  const chatWindowRef = useRef(null); // Reference for the chat window

  useEffect(() => {
    socket.current = io('http://argus.julianwindeck.de/ws');
    
    socket.current.on('connect', () => {
      console.log('Connected to the WebSocket server');
      setMessages([]);
      setCommands([]);
    });

    socket.current.on('reset', () => {
      // Reset the chat window when webserver is reset
    });

    socket.current.on('receive_message', (message) => {
      console.log('Received message from server:', message)
      if(message.type === 'shell') {
        setCommands((prevCommands) => [...prevCommands, message]);
      } else if (message.type === 'current-task') {
        setCurrentGoal(message.text);
      } else if (message.type === 'plan') {
        const parsedPlan = JSON.parse(message.text);
        const newPlanItems = parsedPlan.map(item => `${item.desc} - ${item.check}`);
        setPlanItems(newPlanItems);
      } else {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    socket.current.on('disconnect', () => {
      console.log('Disconnected from the WebSocket server');
    });

    socket.current.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
    });

    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    // Scroll to the bottom of the chat window whenever messages change
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem('messages', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem('commands', JSON.stringify(commands));
  }, [commands]);

  const handleSend = () => {
    if (input.trim()) {
      const newMessage = { text: input, user: 'me', type: 'chat-message'};
      setMessages([...messages, newMessage]);
      setInput('');
      socket.current.emit('send_message', newMessage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="container">
      <div className="chat-container">
        <header className="chat-header">Argus Chat</header>
        <div className="chat-window" ref={chatWindowRef}>
          {messages.map((msg, index) => {
            const htmlContent = marked(msg.text);
            console.log(htmlContent); // Log the parsed HTML
            return (
              <div
                key={index}
                className={`chat-message ${msg.user === 'me' ? 'my-message' : 'other-message'}`}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
            );
          })}
        </div>
        <div className="chat-input">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
          />
          <button onClick={handleSend}>Send</button>
        </div>
      </div>
      <div className="plan-container">
        <PlanComponent currentGoal={currentGoal} planItems={planItems} />
        <ShellComponent commands={commands} />
      </div>
    </div>
  );
};

const PlanComponent = ({ currentGoal, planItems }) => {
  return (
    <div className="plan-component">
      <header className="plan-header">Plan</header>
      {planItems.map((item, index) => (
        <div className="plan-item" key={index}>
          <input type="checkbox" id={`item-${index}`} name={`item-${index}`} />
          <label htmlFor={`item-${index}`}>{item}</label>
        </div>
      ))}
      <div className="current-goal">
        <h2>Current Goal</h2>
        <p>{currentGoal}</p>
      </div>
    </div>
  );
};

const ShellComponent = ({ commands }) => {
  const [input, setInput] = useState('');
  const convert = new AnsiToHtml(); // Initialize the converter

  const handleSend = () => {
    if (input.trim()) {
      setCommands([...commands, { text: input, user: 'me' }]);
      setInput('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <div className="shell-component">
      <div className="shell-window">
        {commands.map((cmd, index) => (
          <div
            key={index}
            className="shell-command"
            dangerouslySetInnerHTML={{ __html: convert.toHtml(cmd.text) }} // Convert ANSI to HTML
          />
        ))}
      </div>
      <div className="shell-input">
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a command..."
          style={{ outline: 'none' }}
        />
      </div>
    </div>
  );
};

const styles = `
  html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  * {
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
  }
  .container {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .chat-container, .plan-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
  }
  .chat-header {
    padding: 10px;
    background-color: #041E42;
    color: #fff;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }
  .chat-window {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
  }
  .chat-message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 2px solid transparent;
  }
  .chat-input {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #fff;
  }
  .chat-input textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
  }
  .chat-input button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #041E42;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .chat-input button:hover {
    background-color: #0056b3;
  }
  .my-message {
    border-color: #041E42;
    align-self: flex-end;
  }
  .other-message {
    border-color: #f8d7da;
    align-self: flex-start;
  }
  .plan-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  .plan-component, .shell-component {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  }
  .plan-header {
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;
    background-color: #041E42;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }
  .plan-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .plan-item input {
    margin-right: 10px;
  }
  .current-goal {
    margin-top: 20px;
  }
  .shell-component {
    background-color: #1e1e1e;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    padding: 10px;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .shell-window {
    flex: 1;
    overflow-y: auto;
  }

  .shell-command {
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  .shell-prompt {
    margin-right: 5px;
  }

  .shell-input {
    display: flex;
    padding: 10px;
  }

  .shell-input textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    font-family: monospace;
    outline: none;
  }
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

ReactDOM.render(
  <ChatApp />,
  document.getElementById('root')
);
